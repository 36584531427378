<template>
    <div class="pb-10 h-100 ml-2 mr-2 mb-20">
        <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
            <div class="breadcrumbactive">
                <div >
                    <span class="pointer" @click="goToCompanyList">Company</span>
                    <span>Business Units</span>
                    <div>List View</div>
                </div>
            </div>
            <div>
                <div class="d-flex align-items-center flex-button">
                    <button type="button" @click="showFilters()" class="btn button-padding btn-smm btn-new-secondary px-4" style="border: 0 !important;border-radius: 6px !important;">
                        <img src="/static/images/filter.svg" />
                        Filter
                    </button>
                    <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" type="button" @click="deleteBusUnit" :disabled="bus_unit_selected.length<1" class="btn button-padding btn-smm btn-danger ml-3" style="border-radius: 6px !important;">
                        <i class="icon icon-minus fs-12 text-white"></i>
                        Delete
                    </button>
                    <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" type="button" @click="$router.push('/businessunit/create/cmpid/'+$route.params.company_id)" class="btn btn-smm button-padding text-white btn-success ml-3" style="border-radius: 6px !important;">
                        <i class="icon icon-plus fs-12 text-white" style="color: #fff !important;"></i>
                        Create New
                    </button>
                </div>
            </div>
        </div>
        <div >
            <div class="w-100">
                <div class="d-flex align-items-center mb-3">
                    <!-- <div class="d-flex align-items-center" >
                        <div class="header-slant-tab">
                            <span class="text-secondary fs-14">List View</span>
                        </div>
                        <div class="header-slant-tab-border"></div>
                    </div> -->
                    <div class="d-flex align-items-center w-100" style="position: relative;">
                        <i class="icon icon-magnify fs-15" style="position: absolute; left: 10px;"></i>
                        <i v-if="search_key !=''" class="icon icon-close fs-20" style="position: absolute; right: 10px;color: #707070 !important;cursor: pointer;" @click="clearSearch"></i>
                        <input type="text" class="search-input-field" autocomplete="nope" v-model="search_key" @input="getListViewData" placeholder="Search by Country, Code, Business Units ... " />
                    </div>
                    <!-- <div class="d-flex justify-content-center ml-2" style="background: rgb(255, 255, 255); border-radius: 20px; width: 150px; height: 2.9em;">
                        <div class="d-flex justify-content-center align-items-center pointer pointer-events-none" style="padding: 0 10px; border-right: 0.5px solid rgb(204, 227, 252); opacity: 0.5;">
                            <img src="/static/images/grid_new.svg" width="18">
                        </div> 
                        <div class="d-flex justify-content-center align-items-center pointer" style="padding: 0 10px; border-left: 0.5px solid rgb(204, 227, 252);">
                            <img src="/static/images/filter_bars_new.svg" width="18">
                        </div>
                    </div> -->
                </div>
                <div class="table-start" style="min-height: 30em;">
                    <table class="admin-new-table w-100 mt-5" style="border-collapse: collapse; position: relative;" width="100%">
                        <thead>
                            <tr>
                                <th class="text-center">
                                    <input v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" type="checkbox" v-model="check_all" @change="checkAll" name="select_all" />
                                </th>
                                <th>
                                    <el-tooltip style="visibility: hidden; opacity: 0;" class="item" effect="dark" content="Edit" placement="top">
                                        <button class="pointer btn-icon pt-3" style="padding:0px!important;">
                                            <img src="/static/images/edit-new.svg" height="15px;">
                                        </button>
                                    </el-tooltip>
                                </th>
                                <th>
                                    Company Name 
                                </th>
                                <th></th>
                                <th>
                                    Business UNITS NAME  
                                </th>
                                <th></th>
                                <th>
                                    BU Code 
                                </th>
                                <th>
                                    Started On 
                                </th>
                                <th>
                                    Country
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="ajax_call_in_progress">
                                <td colspan="7">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!ajax_call_in_progress && list_view_data.length == 0" class="text-center font-weight-bold">
                                <td colspan="7">No Business Units Found</td>
                            </tr>
                            <tr v-else v-for="(bus, index) in list_view_data" :key="bus._id">
                                <td class="text-center">
                                    <input v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" type="checkbox" v-model="bus.checked" @change="selectBusUnit($event, bus.id, index)" name="select_one" />
                                </td>
                                <td>
                                    <el-tooltip v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="item" effect="dark" content="Edit" placement="top">
                                        <button class="pointer btn-icon pt-3" @click="editBusUnit(bus.id)" style="padding:0px!important;">
                                            <img src="/static/images/edit-new.svg" height="15px;">
                                        </button>
                                    </el-tooltip>
                                </td>
                                <td>{{bus.company_name || "-"}}
                                <td></td>
                                <td class="text-truncate" :title="bus.name">
                                    <span @click="goToViewBusUnit(bus.id)" class="text-secondary pointer">{{bus.name || "-"}}</span>
                                </td>
                                <td></td>
                                <td >{{bus.code || "-"}}</td>
                                <td>{{bus.started_on || "-"}}</td>
                                <td>{{bus.country || "-"}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="!ajax_call_in_progress" class="d-flex justify-content-end align-items-center bg-white py-2 px-4 pb-10" style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
                    <multiselect class="diginew-multiselect"
                        :options="pageLimit" :close-on-select="true" v-model.number="limit"
                        open-direction="top" :show-labels="false" placeholder=""
                        style="width: 3.6em !important;padding-right: 10px!important;padding-top: 10px !important;"></multiselect>
                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/down-arrow-grey.svg" style="margin-left: -20px;">
                    <span class="page-info pr-2 pl-6 fw-500" style="height: 2.4rem;line-height: 2.4rem;color: #303031;font-size: 14px;">Per page</span>
                    <button @click="firstPage()"  :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="mt-1 pointer pagination-list">
                        <el-tooltip class="item" effect="dark" content="First Page" placement="top">
                            <i class="icon-chevron-double-left"></i>
                        </el-tooltip>    
                    </button>
                    <button @click="previousPage()"  :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="pagination-list mt-1 ml-2 pointer">
                        <el-tooltip class="item" effect="dark" content="Previous Page" placement="top">
                            <i class="icon icon-chevron-left"></i>
                        </el-tooltip>    
                    </button>
                    <span class="px-2 fw-500 fs-14 mt-1">Showing {{getSkipCount}} - {{total>skip+limit?skip+limit:total}} of {{total}}</span>
                    <button @click="nextPage()" :class="{'not-allowed' : skip_temp >= total }" :disabled="skip_temp >= total" class="pagination-list mt-1 mr-2 pointer">
                        <el-tooltip class="item" effect="dark" content="Next Page" placement="top">
                            <i class="icon icon-chevron-right"></i>
                        </el-tooltip>    
                    </button>
                    <button @click="lastPage()" :class="{'not-allowed' : temp_total == total}" class="mt-1 pointer pagination-list" :disabled ="temp_total == total">
                        <el-tooltip class="item" effect="dark" content="Last Page" placement="top">
                            <i class="icon-chevron-double-right"></i>
                        </el-tooltip>    
                    </button>
                </div>
            </div>
        </div>
        <delete-business-unit-popup v-if="delete_confirm" @hideConfirm="hideConfirm" @deleteConfirm="deleteConfirm" modal_name="delete_confirm"></delete-business-unit-popup>
        <div v-show="display_filters" class="scm-filters fadeInDown" :class="{'fadeInUp':hide_filters == true}" style="background-color: #e6ecff !important;height: 250px !important;">
            <filter-data :showfilterData="showfilterData" @hideFilters="hideFilters" @UpdateByFilter="UpdateByFilter"></filter-data>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
import {HalfCircleSpinner} from 'epic-spinners';
import moment from 'moment';
import { SweetModal } from 'sweet-modal-vue';
import companies from '../mixins/companies';
import globals from '../globals';
import axios from 'axios';
const DeleteBusinessUnitPopup = () => import('./DeleteBusinessUnitPopup');
const FilterData = () => import('./FilterData');
export default {
    mixins: [companies],
    components: {
        HalfCircleSpinner,
        DeleteBusinessUnitPopup,
        FilterData,
        SweetModal
    },
    data() {
        return {
            search_key: '',
            check_all: false,
            skip:0,
            limit: 10,
            warning_msg: '',
            success_msg: '',
            count: 0,
            start: 0,
            end: 0,
            pageLimit: [],
            total: 0,
            skip_temp:0,
            temp_total:0,
            list_view_data: [],
            ajax_call_in_progress: false,
            bus_unit_selected: [],
            delete_confirm: false,
            hide_filters: false,
            display_filters: false,
            showfilterData: {
                started_on:{},
                code:[],
                country:[],
                business_unit:[]
            },
            filterData: {
                started_on:{},
                code:[],
                country:[],
                business_unit:[]
            },
        }
    },
    methods: {
        clearSearch(){
            this.search_key = ""
            this.getListViewData();
        },
        moment(date) {
            return moment(date);
        },
        hideConfirm() {
            this.delete_confirm = false;
        },
        goToCompanyList() {
            this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
        },
        async deleteConfirm() {
            let params = {
                id: this.bus_unit_selected
            }
            await axios.delete(globals.AUTH_SERVICE + `/business_unit/delete`, {
                data: params
            }).then((response) => {
                if(response.data.status_id == 1) {
                    this.delete_confirm = false;
                    this.success_msg = response.data.message
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.getListViewData();
                    }, 2000);
                }
            }).catch((error) => {
                this.ajax_call_in_progress = false;
                this.warning_msg = error.response.data.reason;
                this.$refs.warning_modal.open(); 
            });
        },
        showFilters(){
            if(this.display_filters){
                this.hide_filters = true
                setTimeout(() => {
                    this.display_filters = false
                    this.hide_filters = false
                }, 500);
            }
            else{
                this.display_filters = true
            }
        },
        UpdateByFilter(params){
            this.skip = 0
            this.limit = 10
            this.showfilterData = {
                started_on: params.value1.started_on.length ? {
                    start_date: params.value1.started_on[0].split('/')[2]+'-'+params.value1.started_on[0].split('/')[1]+'-'+params.value1.started_on[0].split('/')[0],
                    end_date: params.value1.started_on[1].split('/')[2]+'-'+params.value1.started_on[1].split('/')[1]+'-'+params.value1.started_on[1].split('/')[0]
                } : {},
                code: params.value1.code,
                country: params.value1.country,
                business_unit: params.value1.business_unit,
            }
            this.filterData = {
                started_on: params.value.started_on.length ? {
                    start_date: params.value.started_on[0].split('/')[2]+'-'+params.value.started_on[0].split('/')[1]+'-'+params.value.started_on[0].split('/')[0],
                    end_date: params.value.started_on[1].split('/')[2]+'-'+params.value.started_on[1].split('/')[1]+'-'+params.value.started_on[1].split('/')[0]
                } : {},
                code: params.value.code,
                country: params.value.country,
                business_unit: params.value.business_unit,
            }
            this.getListViewData()
        },
        removeAllChips(){
            this.showfilterData = {
                started_on: {},
                code:[],
                country:[],
                business_unit:[]
            },
            this.filterData = {
                started_on: {},
                code:[],
                country:[],
                business_unit:[]
            },
            EventBus.$emit('removeAllchip')
            this.getListViewData()
        },
        hideFilters(){
            this.hide_filters = true
            setTimeout(() => {
                this.display_filters = false
                this.hide_filters = false
            }, 500);
        },
        selectBusUnit(event, id) {
            if(event.target.checked) {
                this.bus_unit_selected.push(id);
            } else {
                let index = this.bus_unit_selected.indexOf(id);
                if (index > -1) {
                    this.bus_unit_selected.splice(index, 1);
                }
            }
            if(this.bus_unit_selected.length === this.list_view_data.length) {
                this.check_all = true;
            } else {
                this.check_all = false;
            }
        },
        checkAll(event) {
            if(event.target.checked === true) {
                this.list_view_data.forEach((bus, index) => {
                    this.list_view_data[index].checked = true;
                    this.bus_unit_selected.push(bus.id);
                })
            } else {
                this.list_view_data.forEach((bus, index) => {
                    this.list_view_data[index].checked = false;
                })
                this.bus_unit_selected = [];
            }
        },
        editBusUnit(id) {
            this.$router.push(`/businessunit/edit/cmpid/${this.$route.params.company_id}/${id}`);
        },
        goToViewBusUnit(id) {
            this.$router.push(`/businessunit/view/cmpid/${this.$route.params.company_id}/${id}`);
        },
        previousPage() {
            this.temp_total = 0
            this.skip_temp = 0
            if (this.skip == 0) {
                return;
            }
            this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
            this.getListViewData();
        },
        nextPage() {
            this.skip_temp = this.skip + this.limit > this.total ? this.total : this.skip + this.limit;
            if (this.skip_temp >= this.total) {
                return;
            }
            this.skip = this.skip_temp;
            this.getListViewData();
        },
        firstPage(){
            this.temp_total = 0
            this.skip = 0
            this.limit = this.limit
            this.list_view_data = [] 
            this.getListViewData();
        },
        lastPage() {
            this.skip_temp = 0
            this.skip = 0
            this.temp_total = this.total
            let no_of_pages = Math.ceil(this.total/this.limit)
            this.skip_temp = (no_of_pages * this.limit) - this.limit
            if (this.skip_temp >= this.total) {
                return;
            }
            this.skip = this.skip_temp
            this.getListViewData();
        },
        goToCompanyView() {

        },
        async getListViewData() {
            this.bus_unit_selected = [];
            this.list_view_data = [];
            this.check_all = false;
            this.ajax_call_in_progress = true;
            try {
                let params = {
                    skip: this.skip,
                    limit: this.limit == "" || this.limit == 0 || this.limit < 0 ? 10 : this.limit,
                    search_key: this.search_key,
                    company_id: this.$route.params.company_id,
                    code: this.filterData.code,
                    country: this.filterData.country,
                    name: this.filterData.business_unit,
                    started_on: this.filterData.started_on
                }
                let response = await this.getListBusinessUnit(params);
                if(response.status_id == 1) {
                    this.list_view_data = response.response;
                    this.list_view_data.forEach((bus, index) => {
                        this.list_view_data[index].checked = false;
                    })
                    this.total = response.count;
                }
                this.ajax_call_in_progress = false;
            } catch(err) {
                this.ajax_call_in_progress = false;
            }
        },
        deleteBusUnit() {
            this.delete_confirm = true;
            setTimeout(() => {
                this.$modal.show('delete_confirm');
            }, 500);
        }
    },
    mounted() {
        this.getListViewData();
        // for (let i = 1; i <= 50; i++) {
        //     i += 9
        //     this.pageLimit.push(i);
        // }
    },
    computed: {
        getSkipCount(){
            if(this.total == 0) {
                return 0
            } else {
                let skip_count = this.skip>this.total?this.total:this.skip+1;
                return skip_count;
            }
        },
        loggedInUser() {
            return this.$store.state.loggedInUser;
        },
    },
    created() {
        for (let i = 10; i <= 50; i+=10) {
            this.pageLimit.push(i);
        }
    },
    watch: {
        limit: function(){
            this.getListViewData()
        },
        search_key: function() {
            this.getListViewData();
        },
        count: function() {
            this.getListViewData();
        },
    },
}
</script>
<style scoped>
    .table-view-start {
        background-color: #fff;
    }
    .search-input-field {
        padding: 8px 9.4px 8px 30px;
        border-radius: 18px !important;
        background-color: #fff;
        width: 100%;
    }
    .table-start {
        background-color: #fff;
        padding-top: 5px;
        border-radius: 8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        overflow-x: auto !important;
    }
    .scm-filters{
        height: 435px !important;
        overflow: visible !important;
    }
    @media only screen and (max-width: 350px){
        .flex-button{
            flex-direction: column;
        }
        .button-padding{
            margin-top: 5px;
            margin-bottom: 5px;
            align-items: left !important;
        }
    }
    .not-allowed{
        cursor: no-drop !important;
    }
    .text-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px !important;
    }
</style>